import { Fragment } from 'react';
import PageTitle from '../../components/core/page-title';
import Container from '../../components/layout/container';
import { useTranslation } from 'react-i18next';
import FollowUpItem from '../../components/summary/followup-item';
import FollowUpBookingSummary from '../../components/booking/followUp-booking-summary ';
import { useParams } from 'react-router-dom';
import { formatUrlParamsToNumber } from '../../utils';

export const FollowUpBooking = () => {
	const { consultationId, followUpId } = useParams();

	

	const { t } = useTranslation(['default']);
	const followup = formatUrlParamsToNumber(followUpId);
	const consultation = formatUrlParamsToNumber(consultationId) 



	return (
		<Fragment>
			<Container sx={{ flex: '1', justifyContent: 'flex-start' }}>
				<PageTitle heading={`${t('tr.follow-up')}`} />
				<FollowUpBookingSummary consultationId={consultation} id={followup} />
			</Container>
		</Fragment>
	);
};
